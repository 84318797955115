// import dependencies
import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./filters";

import axios from "axios";
import VueCarousel from "vue-carousel";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueLazyload from "vue-lazyload";
import Meta from "vue-meta";
import Config from "../config.js";
import AOS from "aos";
import "aos/dist/aos.css";
import RotateText from "./components/RotateText"
import NextPage from "./components/NextPage"

// Global components
Vue.component('rotate-text', RotateText);
Vue.component('next-page', NextPage);

// implement packages
Vue.config.productionTip = false;
Vue.prototype.$config = Config;
Vue.prototype.$http = axios;

Vue.use(Meta);
Vue.use(VueCarousel);
Vue.use(Toast);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "/img/lazyload.gif",
  loading: "/img/lazyload.gif",
  attempt: 1,
});


new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    AOS.init();
  },
}).$mount("#app");