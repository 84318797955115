import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

let stateless = new Vuex.Store({
  state: {
    posts: [],
    projects: [],
    sync: false,
    appConfig: {}
  },

  getters: {
    getProject: (state) => (slug) => {
      let projects = state.projects;
      let project = projects.find(project => project.slug == slug);
      return (project) ? project : {
        title: "",
        created_at: "",
        content: "",
        images: [],
      };
    },
    getPost: (state) => (slug) => {
      let posts = state.posts;
      let post = posts.find(post => post.slug == slug);
      return (post) ? post : {
        title: "",
        created_at: "",
        content: ""
      };
    },
  },
  actions: {
    fetchData: async ({
      state
    }) => {
      state.appConfig = Vue.prototype.$config;

      try {
        let response = await axios.get(`${state.appConfig.api.url}/general/fetch/all`);
        document.querySelector("body").classList.add("loaded");
        state.sync = true;
        state.projects = response.data.data.projects;
        state.posts = response.data.data.posts;
      } catch (error) {
        document.querySelector("body").classList.add("loaded");
        state.sync = false;
        console.log(error)
      }

    },
  }
})

export default stateless