<template>
  <div>
    <nav>
      <div class="row">
        <div class="col-4">
          <div class="logo">
            <span v-show="navopen">
              <a @click="switchTheme()">
                <svg
                  v-show="is_dark"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 20 30"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-sun"
                >
                  <circle cx="12" cy="12" r="5" />
                  <line x1="12" y1="1" x2="12" y2="3" />
                  <line x1="12" y1="21" x2="12" y2="23" />
                  <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                  <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                  <line x1="1" y1="12" x2="3" y2="12" />
                  <line x1="21" y1="12" x2="23" y2="12" />
                  <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                  <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                </svg>
                <svg
                  v-show="!is_dark"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 20 30"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-moon"
                >
                  <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
                </svg>
              </a>
            </span>

            <span v-show="!navopen">
              <router-link to="/">
                <a @click="closeNavbar()" class="theme-font-color">{{ $config.appname }}</a>
              </router-link>
            </span>
          </div>
          <!-- /logo -->
        </div>
        <div class="col-8 text-right">
          <ul class="socials">
            <li>
              <a @click="switchTheme()">
                <svg
                  v-show="is_dark"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 20 30"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-sun"
                >
                  <circle cx="12" cy="12" r="5" />
                  <line x1="12" y1="1" x2="12" y2="3" />
                  <line x1="12" y1="21" x2="12" y2="23" />
                  <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                  <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                  <line x1="1" y1="12" x2="3" y2="12" />
                  <line x1="21" y1="12" x2="23" y2="12" />
                  <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                  <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                </svg>
                <svg
                  v-show="!is_dark"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 20 30"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-moon"
                >
                  <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
                </svg>
              </a>
            </li>

            <li>
              <a target="_blank" :href="`https://linkedin.com/in/${this.$config.socials.linkedin}`">
                <span class="ti-linkedin"></span>
              </a>
            </li>
            <li>
              <a target="_blank" :href="`http://twitter.com/${this.$config.socials.twitter}`">
                <span class="ti-twitter"></span>
              </a>
            </li>
          </ul>

          <div class="vertical-divider"></div>

          <div class="menu-toggle cool-menu" :class="{ open: navopen }" @click="toggleNavbar()">
            <div class="menu-icon">
              <div class="menu-line menu-line--1"></div>
              <div class="menu-line menu-line--2"></div>
              <div class="menu-line menu-line--3"></div>
              <div class="menu-line menu-line--4"></div>
              <div class="menu-line menu-line--5"></div>
            </div>
          </div>
          <!-- /menu-toggle -->
        </div>
      </div>
    </nav>
    <!-- /nav -->
    <div class="menu-wrapper" :class="{ show: navopen }">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-md-4" v-for="(nav, index) in navlinks" :key="index">
          <div class="link-item link-item-1" :class="{ show: navopen }" @click="toggleNavbar()">
            <router-link :to="nav.link">
              <a class="navlink">
                <img :src="nav.image" class="rounded-circle" alt="-" />
                <h3>{{ nav.title }}</h3>
                <p v-html="nav.content"></p>
                <div class="arrow-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="40"
                    height="40"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path
                      d="M 21.1875 9.28125 L 19.78125 10.71875 L 24.0625 15 L 4 15 L 4 17 L 24.0625 17 L 19.78125 21.28125 L 21.1875 22.71875 L 27.90625 16 Z"
                    />
                  </svg>
                </div>
              </a>
            </router-link>
          </div>
          <!-- /link-item -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      navopen: false,
      is_dark: true,
      navlinks: [
        {
          title: "Articles",
          image: "/img/user-photo-sq.jpg",
          content: "I have nice articles<br>Check them out",
          link: "/articles",
        },
        {
          title: "Projects",
          image: "/img/projects-sq.jpg",
          content: "I have some projects.<br />Check them out.",
          link: "/projects",
        },
        {
          title: "Contact",
          image: "/img/contact-sq.jpg",
          content: "Just say Hi.<br />or let's discuss about project.",
          link: "/contact",
        },
      ],
    };
  },
  methods: {
    toggleNavbar() {
      this.navopen = !this.navopen;
    },
    closeNavbar() {
      if (this.navopen) {
        this.navopen = false;
      }
    },
    changeData() {
      this.is_dark = !this.is_dark;
    },
    switchTheme() {
      let theme = localStorage.getItem("theme");
      if (!theme) {
        document.documentElement.setAttribute("data-theme", "dark");
        this.is_dark = true;
        this.theme = "dark";
        localStorage.setItem("theme", "dark");
      } else if (theme == "light") {
        document.documentElement.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
        this.theme = "dark";
        this.is_dark = true;
      } else if (theme == "dark") {
        document.documentElement.setAttribute("data-theme", "light");
        localStorage.setItem("theme", "light");
        this.is_dark = false;
        this.theme = "light";
      }
    },
    applyTheme(theme) {
      if (theme == "dark") {
        document.documentElement.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
        this.is_dark = true;
      } else if (theme == "light") {
        document.documentElement.setAttribute("data-theme", "light");
        localStorage.setItem("theme", "light");
        this.is_dark = false;
      }
    },
  },
  mounted() {
    this.applyTheme(localStorage.getItem("theme"));
  },
};
</script>
