<template>
  <div class="next-page">
    <div class="row">
      <div class="col" data-aos="fade-right">
        <router-link :to="path">
          <a>
            <div class="arrow-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="40"
                height="40"
                fill="var(--font-color)"
                viewBox="0 0 32 32"
              >
                <path
                  d="M 21.1875 9.28125 L 19.78125 10.71875 L 24.0625 15 L 4 15 L 4 17 L 24.0625 17 L 19.78125 21.28125 L 21.1875 22.71875 L 27.90625 16 Z"
                />
              </svg>
            </div>
            <h2>{{title}}</h2>
            <p>{{content}}</p>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NextPage",
  props: ["title", "content", "path"],
};
</script>