<template>
  <div class="row">
    <div class="col">
      <div class="copyright-text">
        <a class="scrollTop" @click="scrollUp()">
          <div class="arrow-top">
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              fill-rule="evenodd"
              clip-rule="evenodd"
            >
              <path
                d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z"
              />
            </svg>
          </div>
        </a>
        <p>&copy; {{year}} {{this.$config.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Myfooter",
  methods: {
    scrollUp() {
      let el = document.querySelector("#app");
      if (el) {
        el.scrollIntoView();
      }
    },

    ...mapActions(["fetchData"]),
  },
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
    ...mapState(["projects", "sync", "posts"]),
  },
  mounted() {
    this.fetchData();
  },
};
</script>
