<template>
  <p class="rotate-text text-center mb-4">
    <marquee>
      <slot></slot>
    </marquee>
  </p>
</template>

<script>
export default {
  name: "RotateText",
};
</script>

<style>
</style>