<template>
  <div id="app">
    <div class="loader">
      <p class="loader-text">loading</p>
    </div>

    <div class="main-wrapper">
      <header>
        <navbar></navbar>
      </header>
      <router-view></router-view>
      <myfooter></myfooter>
    </div>
  </div>
</template>
<script>
import Navbar from "./components/Navbar";
import Myfooter from "./components/Footer";

export default {
  name: "App",
  components: {
    Navbar,
    Myfooter,
  },
};
</script>

<style></style>
