import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
const Home = () => import('../pages/Home')
const About = () => import('../pages/About')
const Contact = () => import('../pages/Contact.vue')
const NotFound = () => import('../pages/NotFound.vue')
const ProjectHome = () => import('../pages/project/Index.vue')
const ProjectShow = () => import('../pages/project/Show.vue')
const ArticleHome = () => import('../pages/article/Index.vue')
const ArticleShow = () => import('../pages/article/Show.vue')

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: Home
  },

  {
    path: '/about',
    name: 'about',
    component: About
  },

  {
    path: '/articles',
    name: 'articles',
    component: ArticleHome
  },

  {
    path: '/articles/:slug',
    name: 'articles.show',
    component: ArticleShow
  },

  {
    path: '/projects',
    name: 'projects',
    component: ProjectHome
  },

  {
    path: '/projects/:slug',
    name: 'projects.show',
    component: ProjectShow
  },

  {
    path: '*',
    name: 'notfound',
    component: NotFound
  },

  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes
});

router.beforeResolve((to, from, next) => {

  if (to.name) {
    NProgress.start()
  }

  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router